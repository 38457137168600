import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RowndProvider } from '@rownd/react';

ReactDOM.render(
  <RowndProvider 
    appKey="key_wjv7lubb1gxez831wenbpz6f"
    onLoad={() => console.log('Rownd loaded successfully')}
  >
    <App />
  </RowndProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
