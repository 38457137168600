import React, { useState, useEffect } from 'react';
import api from '../api-client'; // Ensure this is the correct path to your API client

function ImageUploader({ horseId, initialImageUrls = [], onUploadComplete }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [imageUrls, setImageUrls] = useState(Array.isArray(initialImageUrls) ? initialImageUrls : []);

  useEffect(() => {
    // Ensure imageUrls is updated if initialImageUrls changes
    setImageUrls(Array.isArray(initialImageUrls) ? initialImageUrls : []);
  }, [initialImageUrls]);

  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      console.error('No files selected for upload.');
      return;
    }

    setUploading(true);
    try {
      const uploadPromises = selectedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        const response = await api.post(`/api/media/horse/${horseId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${file.name}`);
        }

        const data = await response.json();
        return data; // Assuming the response contains the URLs
      });

      const uploadedImages = await Promise.all(uploadPromises);
      onUploadComplete(uploadedImages);

      // Reset the uploader
      setSelectedFiles([]);
      setImageUrls((prevUrls) => [...prevUrls, ...uploadedImages.flatMap(img => img.url)]);
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleImageClick = (url) => {
    window.open(url, '_blank');
  };

  const handleAddImageClick = (e) => {
    e.preventDefault(); // Prevent default action to avoid form submission
    document.getElementById('file-input').click();
  };

  return (
    <div className="image-uploader">
      <div className="image-gallery">
        {Array.isArray(imageUrls) ? imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Thumbnail ${index}`}
            style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px', cursor: 'pointer' }}
            onClick={() => handleImageClick(url)}
          />
        )) : (
          <div>No images uploaded.</div>
        )}
        <button onClick={handleAddImageClick} className="add-image-button">
          +
        </button>
      </div>
      <input
        id="file-input"
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {selectedFiles.length > 0 && (
        <button onClick={handleUpload} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload Images'}
        </button>
      )}
    </div>
  );
}

export default ImageUploader;
