// api-client.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://horse-trainer-backend.robert-9e7.workers.dev', // Replace with your actual API base URL
  withCredentials: true, // This is important for CORS with credentials
});

let getAccessToken;
let getUserId;

export function setAccessTokenHelper(fn) {
  getAccessToken = fn;
}

export function setUserIdHelper(fn) {
  getUserId = fn;
}

api.interceptors.request.use(async (config) => {
  console.log('Interceptor: Starting request', config.url);
  if (getAccessToken && getUserId) {
    const token = await getAccessToken();
    if (token) {
      console.log('Interceptor: Access Token:', token);
      config.headers.Authorization = `Bearer ${token}`;
      
      const userId = await getUserId();
      console.log('Interceptor: User ID:', userId);

      if (userId) {
        config.url = config.url.replace('{user_id}', userId);
        console.log('Interceptor: Updated URL:', config.url);
      } else {
        console.error('Interceptor: User ID not available');
      }
    } else {
      console.log('Interceptor: No token available');
    }
  } else {
    console.log('Interceptor: getAccessToken or getUserId not available');
  }
  
  return config;
});

api.interceptors.response.use(
  response => {
    console.log('API Response:', response);
    return response;
  },
  error => {
    console.error('API Error:', error);
    return Promise.reject(error);
  }
);

export default api;
