import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useRownd } from '@rownd/react';
import api, { setAccessTokenHelper, setUserIdHelper } from './api-client';
import Menu from './components/Menu';
import Horses from './components/horses';

function App() {
  const { is_authenticated, user, requestSignIn, getAccessToken } = useRownd();
  const [accountInfo, setAccountInfo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAccessTokenHelper(getAccessToken);
    setUserIdHelper(() => user?.data?.user_id);
  }, [getAccessToken, user]);

  useEffect(() => {
    const userId = user?.data?.user_id;
    if (is_authenticated && userId) {
      fetchOrCreateAccount(userId);
    } else {
      setAccountInfo(null);
      setError(null);
    }
  }, [is_authenticated, user?.data?.user_id]);

  const fetchOrCreateAccount = async (userId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(`/api/user/${userId}/accounts/data`);
      setAccountInfo(response.data);
    } catch (error) {
      setError('Failed to fetch or create account information. Please try again.');
      setAccountInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = () => {
    requestSignIn();
  };

  return (
    <Router>
      <div>
        <h1>Welcome, {is_authenticated ? user?.data?.full_name || 'User' : 'Guest'}!</h1>
        {!is_authenticated && <button onClick={handleSignIn}>Sign In</button>}
        <button onClick={() => fetchOrCreateAccount(user?.data?.user_id)} disabled={isLoading || !is_authenticated}>
          {isLoading ? 'Fetching...' : 'Fetch Account Info'}
        </button>
        {error && <p style={{color: 'red'}}>{error}</p>}
        {isLoading && <p>Loading account information...</p>}
        {accountInfo && (
          <div>
            <h2>Account Information</h2>
            <p>User ID: {accountInfo.id}</p>
            <p>Name: {accountInfo.name || 'Not set'}</p>
          </div>
        )}
        <Menu
          userName={user?.data?.full_name || 'User'}
          onUserInfoClick={() => {}}
          onHorsesClick={() => {}}
        />
        <Routes>
          <Route path="/horses" element={<Horses />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
