import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css'; // Assuming you have a CSS file for styling

function Menu({ userName, onUserInfoClick }) {
  const navigate = useNavigate();

  return (
    <div className="menu">
      <div className="menu-item" onClick={onUserInfoClick}>
        <h2>User Info</h2>
        <p>{userName}</p>
      </div>
      <div className="menu-item" onClick={() => navigate('/horses')}>
        <h2>Horses</h2>
      </div>
    </div>
  );
}

export default Menu;
