import React, { useState } from 'react';
import api from '../api-client'; // Ensure this is the correct path to your API client
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import { useRownd } from '@rownd/react'; // Import useRownd hook
import './horses.css'; // Import the CSS file
import ImageUploader from './ImageUploader'; // Import the ImageUploader component

function Horses() {
  const { user } = useRownd(); // Access user data from Rownd
  const [horses, setHorses] = useState([]);
  const [selectedHorse, setSelectedHorse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    HorseName: '',
    Breeding: '',
    BirthDate: '',
    PurchasePrice: '',
    PurchaseDate: '',
    TrainingEvents: '',
    Pictures: '[]', // Default to an empty JSON array string
    Videos: '',
    VerifiedCapabilities: ''
  });

  const fetchHorses = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const userId = user?.data?.user_id; // Get user ID from Rownd
      if (!userId) {
        throw new Error('User ID not available');
      }
      const response = await api.get(`/api/user/${userId}/horses/data`);
      setHorses(response.data);
    } catch (error) {
      setError('Failed to fetch horses. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHorseData = async (horseId) => {
    setIsLoading(true);
    setError(null);
    try {
      const userId = user?.data?.user_id;
      const response = await api.get(`/api/user/${userId}/horses/${horseId}/data`);
      const horseData = response.data;
      setSelectedHorse(horseData);
      setFormData({
        ...horseData,
        Pictures: JSON.parse(horseData.Pictures || '[]') // Parse the Pictures field
      });
    } catch (error) {
      setError('Failed to fetch horse data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const horseId = uuidv4(); // Generate a new horse ID
    const userId = user?.data?.user_id; // Get user ID from Rownd
    if (!userId) {
      setError('User ID not available');
      return;
    }
    try {
      await api.post(`/api/user/${userId}/horses/${horseId}`, {
        ...formData,
        Pictures: JSON.stringify(formData.Pictures) // Stringify the Pictures field
      });
      setShowForm(false);
      fetchHorses(); // Refresh the list of horses
    } catch (error) {
      setError('Failed to add horse. Please try again.');
    }
  };

  const handleSave = async () => {
    const userId = user?.data?.user_id;
    if (!userId || !selectedHorse) {
      setError('User ID or Horse ID not available');
      return;
    }
    try {
      await api.patch(`/api/user/${userId}/horses/${selectedHorse.UUID}`, {
        ...formData,
        Pictures: JSON.stringify(formData.Pictures) // Stringify the Pictures field
      });
      setError(null);
      fetchHorses(); // Refresh the list of horses
    } catch (error) {
      setError('Failed to update horse. Please try again.');
    }
  };

  const handleAddHorse = () => {
    setShowForm(true);
    setSelectedHorse(null); // Clear selected horse
    setFormData({
      HorseName: '',
      Breeding: '',
      BirthDate: '',
      PurchasePrice: '',
      PurchaseDate: '',
      TrainingEvents: '',
      Pictures: '[]', // Reset to an empty JSON array string
      Videos: '',
      VerifiedCapabilities: ''
    }); // Reset form data
  };

  const handleCancel = () => {
    setShowForm(false);
    setError(null);
  };

  const handleUploadComplete = async (uploadedImages) => {
    const imageUrls = uploadedImages.map(image => image.url);
    setFormData(prevFormData => ({
      ...prevFormData,
      Pictures: [...prevFormData.Pictures, ...imageUrls]
    }));

    // Fetch the updated horse data
    if (selectedHorse) {
      await fetchHorseData(selectedHorse.UUID);
    }
  };

  return (
    <div className="horses-container">
      <h1>Horses</h1>
      <p>Here you can see and add your horses.</p>
      <button onClick={fetchHorses} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Get Horses'}
      </button>
      <button onClick={handleAddHorse}>Add Horse</button>
      {error && <p className="error-message">{error}</p>}
      {!showForm && horses.length === 0 ? (
        <p>No horses</p>
      ) : (
        !showForm && (
          <table>
            <thead>
              <tr>
                <th>Horse Name</th>
                <th>Horse ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {horses.map((horse) => (
                <tr key={horse.UUID}>
                  <td>{horse.HorseName}</td>
                  <td>{horse.UUID}</td>
                  <td>
                    <button onClick={() => fetchHorseData(horse.UUID)}>View Details</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
      {selectedHorse && !showForm && (
        <div>
          <h2>Horse Details</h2>
          <form>
            <div className="form-group">
              <label>Horse Name <span className="required">*</span></label>
              <input type="text" name="HorseName" value={formData.HorseName} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Breeding</label>
              <input type="text" name="Breeding" value={formData.Breeding} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Birth Date</label>
              <input type="date" name="BirthDate" value={formData.BirthDate} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Purchase Price</label>
              <input type="number" name="PurchasePrice" value={formData.PurchasePrice} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Purchase Date</label>
              <input type="date" name="PurchaseDate" value={formData.PurchaseDate} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Training Events</label>
              <input type="text" name="TrainingEvents" value={formData.TrainingEvents} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Pictures</label>
              <ImageUploader
                horseId={selectedHorse.UUID}
                initialImageUrls={formData.Pictures}
                onUploadComplete={handleUploadComplete}
              />
            </div>
            <div className="form-group">
              <label>Videos</label>
              <input type="text" name="Videos" value={formData.Videos} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Verified Capabilities</label>
              <input type="text" name="VerifiedCapabilities" value={formData.VerifiedCapabilities} onChange={handleInputChange} />
            </div>
          </form>
          <button onClick={handleSave}>Save</button>
        </div>
      )}
      {showForm && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Horse Name <span className="required">*</span></label>
            <input type="text" name="HorseName" value={formData.HorseName} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Breeding</label>
            <input type="text" name="Breeding" value={formData.Breeding} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Birth Date</label>
            <input type="date" name="BirthDate" value={formData.BirthDate} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Purchase Price</label>
            <input type="number" name="PurchasePrice" value={formData.PurchasePrice} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Purchase Date</label>
            <input type="date" name="PurchaseDate" value={formData.PurchaseDate} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Training Events</label>
            <input type="text" name="TrainingEvents" value={formData.TrainingEvents} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Pictures</label>
            <ImageUploader
              horseId={null}
              initialImageUrls={JSON.parse(formData.Pictures || '[]')}
              onUploadComplete={handleUploadComplete}
            />
          </div>
          <div className="form-group">
            <label>Videos</label>
            <input type="text" name="Videos" value={formData.Videos} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Verified Capabilities</label>
            <input type="text" name="VerifiedCapabilities" value={formData.VerifiedCapabilities} onChange={handleInputChange} />
          </div>
          <button type="submit">Submit</button>
          <button type="button" onClick={handleCancel}>Cancel</button>
        </form>
      )}
    </div>
  );
}

export default Horses;


